import 'bootstrap'
import './scss/index.scss'

$(document).ready(function($) {
    let $categorylistcont = $('.category-list-cont');
    let $categorylist = $('.category-list');
    $categorylist.find('.category-elem').mouseenter(function() {
        $categorylist.find('.category-elem').removeClass('active');
        $(this).addClass('active');
        let num = $(this).attr('data-category-num');
        $categorylistcont.find('.category-detail').removeClass('d-block');
        $categorylistcont.find('.category-detail[data-category-num='+num+']').addClass('d-block');
    });
    $categorylistcont.mouseleave(function() {
        $categorylistcont.find('.category-detail').removeClass('d-block');
        $categorylist.find('.category-elem').removeClass('active');
    });


    let $frontpagecarouseltexts = $('#frontpagecarouseltexts');
    let $frontpagecarousel = $('#frontpagecarousel');
    $frontpagecarousel.on('slid.bs.carousel', function() {
        let item = $(this).find('.carousel-item.active').attr('data-item');
        $frontpagecarouseltexts.find('.nav-link.active').removeClass('active');
        $frontpagecarouseltexts.find('.nav-link[data-item='+item+']').addClass('active');
    });
    $frontpagecarouseltexts.find('.nav-link').click(function() {
        let item = parseInt($(this).attr('data-item'));
        $frontpagecarousel.carousel(item);
    });

    $('.carousel-prev').click(function() {
        $('#'+$(this).attr('data-target')).carousel('prev');
    });
    $('.carousel-next').click(function() {
        $('#'+$(this).attr('data-target')).carousel('next');
    });

    let $productCarousel = $('#productCarousel');
    let itemnumber = 0;
    $productCarousel.find('.carousel-item').each(function() {
        $(this).attr('data-index', itemnumber);
        $productCarousel.find('.indicators').append('<a data-index="'+itemnumber+'"></a>');
        itemnumber++;
    });
    $productCarousel.find('.indicators a').first().addClass('active');
    $productCarousel.on('slide.bs.carousel', function(e) {
        let $e = $(e.relatedTarget);
        let idx = $e.index();
        let itemsPerSlide = 4;
        let $this = $(this);
        let $inner = $this.find('.carousel-inner');
        let totalItems = $this.find('.carousel-item').length;

        $this.find('.indicators a').removeClass('active').eq(parseInt($e.attr('data-index'))).addClass('active');

        if (idx >= totalItems-(itemsPerSlide-1)) {
            let it = itemsPerSlide - (totalItems - idx);
            for (let i=0; i<it; i++) {
                // append slides to end
                if (e.direction==='left') {
                    $this.find('.carousel-item').eq(i).appendTo($inner);
                } else {
                    $this.find('.carousel-item').eq(0).appendTo($inner);
                }
            }
        }
    });
});
